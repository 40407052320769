import * as React from "react";
import { Heading, Strapline } from "../../components/Heading";
import { Spacer } from "../../components/Spacer";
import { Row } from "../Grid/Row";
import {
  enumerationCss,
  iconCss,
  definitionNameCss,
  definitionTextCss,
  definitionDescriptionCss,
} from "./index.css";

export const ProcessBlock: React.FC<{
  title: string;
  strapline: string;
  description: string;
  statements: Array<{ title: string; description: string }>;
}> = function ProcessBlock({ title, strapline, description, statements }) {
  return (
    <Row>
      <div />
      <div>
        <Strapline>{strapline}</Strapline>
        <Spacer size="extraLarge" />
        <Heading as="h2">{title}</Heading>
        <Spacer size="medium" />
        {description?.split("\n")?.map((content, index) => {
          return <p key={index}>{content}</p>;
        })}

        <Spacer size="extraLarge" />
        <dl className={enumerationCss}>
          {statements.map((statement, index) => {
            return (
              <React.Fragment key={statement.id}>
                <dt className={definitionNameCss}>
                  <svg
                    viewBox="0 0 140 148"
                    className={iconCss}
                    role="presentation"
                  >
                    <use xlinkHref="#logo"></use>
                  </svg>
                  <span className={definitionTextCss}>{statement.title}</span>
                </dt>
                <dd className={definitionDescriptionCss}>
                  {statement.description}
                </dd>
              </React.Fragment>
            );
          })}
        </dl>
        <Spacer size={[null, "extraLarge"]} />
        <Spacer size={[null, "extraLarge"]} />
      </div>
    </Row>
  );
};
