import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useTitle, useMeta } from "hoofd";
import {
  MastHead,
  Headline,
  BackgroundImage,
} from "../modules/MastHead/index.ts";

import { Strapline } from "../components/Heading";
import { MissionBlock } from "../modules/MissionBlock/index.tsx";
import { ContentBlock } from "../modules/ContentBlock/index.tsx";
import { ProcessBlock } from "../modules/ProcessBlock/index.tsx";
import { Container } from "../components/Container.tsx";
import DefaultLayout from "../layout/default";
import { sectionCss } from "../atomics.css";
import { Spacer } from "../components/Spacer";

import {
  processBlockSectionCss,
  processBlockBackgroundCss,
  processBlockContainerCss,
} from "./over-ons.css";

// markup
const IndexPage = ({ data: { contentfulPage } }) => {
  let serviceBlockIndex = 0;

  useTitle(contentfulPage.pageTitle || contentfulPage.title);
  useMeta({ name: "description", content: contentfulPage.description });

  return (
    <DefaultLayout>
      <MastHead>
        <Strapline theme="light">{contentfulPage.strapline}</Strapline>
        <Spacer size="extraLarge" />
        <Headline title={contentfulPage.title} />
        <BackgroundImage
          image={
            contentfulPage.titleBackground?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          alt=""
          role="presentation"
        />
      </MastHead>

      {contentfulPage.contentBlocks.map((node, index) => {
        if (node.__typename === "ContentfulServiceBlock") {
          serviceBlockIndex++;
          return (
            <section key={node.id} className={sectionCss}>
              <Container>
                <ContentBlock
                  layout={serviceBlockIndex % 2 === 0 ? "default" : "swap"}
                  title={node.title}
                  strapline={node.strapline}
                  description={node.description}
                  image={node.image?.localFile.childImageSharp.gatsbyImageData}
                  imageDescription={node.image.title}
                />
              </Container>
            </section>
          );
        }
        serviceBlockIndex = 1;

        if (node.__typename === "ContentfulMissionBlock") {
          return (
            <section
              key={node.id}
              className={sectionCss}
              style={{ background: "#d8dee7" }}
            >
              <Container>
                <MissionBlock
                  title={node.title}
                  description={node.description}
                />
              </Container>
            </section>
          );
        }

        if (node.__typename === "ContentfulProcessBlock") {
          return (
            <section key={node.id} className={processBlockSectionCss}>
              <div className={processBlockBackgroundCss}>
                <GatsbyImage
                  image={
                    node.background.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={node.title}
                  objectFit="fill"
                  loading="eager"
                  role="presentation"
                />
              </div>
              <Container className={processBlockContainerCss}>
                <ProcessBlock
                  title={node.title}
                  strapline={node.strapline}
                  description={node.description}
                  statements={node.statements}
                />
              </Container>
            </section>
          );
        }

        return null;
      })}
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "over-ons" }) {
      title
      strapline
      titleBackground {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, height: 242)
          }
        }
      }
      pageTitle
      description
      contentBlocks {
        ... on ContentBlock {
          __typename
          id
          title
          description
        }
        ... on ContentfulServiceBlock {
          strapline
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 550
                  placeholder: TRACED_SVG
                  quality: 80
                )
              }
            }
          }
        }

        ... on ContentfulProcessBlock {
          id
          strapline
          background {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  breakpoints: [640, 750, 828, 1080, 1200, 1920]
                  quality: 100
                )
              }
            }
          }
          statements {
            id
            title
            description
          }
        }
      }
    }
  }
`;

export default IndexPage;
