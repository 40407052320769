import * as React from "react";
import { Heading } from "../../components/Heading";
import { Spacer } from "../../components/Spacer";
import { containerCss, iconCss } from "./index.css";

export const MissionBlock: React.FC<{
  title: string;
  description: string;
}> = function MissionBlock({ title, description }) {
  return (
    <div className={containerCss}>
      <Spacer size="extraLarge" />
      <Spacer size="extraLarge" />
      <svg viewBox="0 0 140 148" className={iconCss}>
        <use xlinkHref="#logo"></use>
      </svg>
      <Spacer size="extraLarge" />
      <Heading as="h2">{title}</Heading>
      <Spacer size="extraLarge" />
      {description?.split("\n")?.map((content, index) => {
        return <p key={index}>{content}</p>;
      })}
      <Spacer size="extraLarge" />
      <Spacer size="extraLarge" />
    </div>
  );
};
